const contentful = require('contentful');

import getConfig from 'next/config';

const {
  publicRuntimeConfig: { contentful: contentfulConfig },
} = getConfig();

export const cfulClient = contentful.createClient({
  ...contentfulConfig,
  resolveLinks: true,
  removeUnresolved: true,
});
