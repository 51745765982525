import styled from 'styled-components';
import { useTranslations } from 'ilenia';

import { InvestorsFooter } from 'components/InvestorsFooter';
import NavigationInvestor from 'components/NavigationInvestor';
import { BannerProvider } from '@bscom/components/PromotionalBanner';

const PageWrapper = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden;
`;

const MainArea = styled.main`
  flex-grow: 1;
`;

export const BaseLayout = ({ children }) => {
  const [, localeCode] = useTranslations();

  return (
    <BannerProvider>
      <PageWrapper>
        <NavigationInvestor localeCode={localeCode} />
        <MainArea id="main">{children}</MainArea>
        <InvestorsFooter />
      </PageWrapper>
    </BannerProvider>
  );
};
