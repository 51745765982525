import React from 'react';
import { useTranslations } from 'ilenia';
import styled from "styled-components";
import { DARK_GREEN } from "lib/constants";

import {
  FooterItem,
  FooterItems,
  FooterSpacing,
  FooterItemText,
} from '@bscom/components/Footer/FooterComponents';
import { Link, StyledLink } from '@bscom/components/Links';
import { Footer } from '@bscom/components/Footer/BaseFooter';
import { BusinessFooterSections } from '@bscom/components/Footer/BusinessFooter';

const InvestorsFooterBottom = ({
  isCCPA,
  ccpaText,
  legalSiteUrl,
  consumerSiteUrl,
}) => {
  const [translations, localeCode] = useTranslations();

  return (
    <FooterSpacing>
      <FooterItems marginBottom>
        <FooterItem floatLeft marginRight>
          <Link
            href={`${legalSiteUrl}/for-businesses/terms-of-use-and-sale-for-businesses`}
            target="_blank"
          >
            <FooterItemText>
              {translations['footer/items/termsconditions']}
            </FooterItemText>
          </Link>
        </FooterItem>
        <FooterItem floatLeft marginRight>
          <Link
            href={`${legalSiteUrl}/for-investors/privacy-notice`}
            target="_blank"
          >
            <FooterItemText>
              {translations['footer/items/privacypolicy']}
            </FooterItemText>
          </Link>
        </FooterItem>
        <FooterItem floatLeft marginRight>
          <Link
            href={`${legalSiteUrl}/for-businesses/guidelines-for-businesses`}
            target="_blank"
          >
            <FooterItemText>
              {translations['footer/items/companyguidelines']}
            </FooterItemText>
          </Link>
        </FooterItem>
        <FooterItem floatLeft marginRight>
          <Link
            href={`${legalSiteUrl}/for-businesses/trademark-guidelines`}
            target="_blank"
          >
            <FooterItemText>
              {translations['footer/items/trademarkguidelines']}
            </FooterItemText>
          </Link>
        </FooterItem>
        <FooterItem floatLeft marginRight>
          <StyledLink
            href=""
            onClick={() => window.Optanon.ToggleInfoDisplay()}
          >
            <FooterItemText>{ccpaText}</FooterItemText>
          </StyledLink>
        </FooterItem>
        {(localeCode === 'en-US' || localeCode === 'en') && isCCPA && (
          <FooterItem floatLeft marginRight>
            <Link
              href={`${consumerSiteUrl}/do-not-sell-my-info`}
              target="_blank"
            >
              <FooterItemText>Do Not Sell My Info</FooterItemText>
            </Link>
          </FooterItem>
        )}
        <FooterItem floatLeft marginRight>
          <Link
            href={`${legalSiteUrl}/for-everyone/modern-slavery-and-human-trafficking-statement`}
            target="_blank"
          >
            <FooterItemText>Modern Slavery Statement</FooterItemText>
          </Link>
        </FooterItem>
      </FooterItems>
      <FooterItems>
        <FooterItem>
          <FooterItemText theme={{ secondaryText: '#666684' }}>
            {`© ${new Date().getFullYear()} ${
              localeCode === 'en-US' || localeCode === 'en'
                ? 'Trustpilot, Inc'
                : 'Trustpilot A/S'
            }. ${translations['footer/items/allrightsreserved']}`}
          </FooterItemText>
        </FooterItem>
      </FooterItems>
    </FooterSpacing>
  );
};

export const InvestorsFooter = (props) => {
  return (
    <StyledContainer>
    <Footer
      sections={(restProps) => (
        <BusinessFooterSections filter="false" {...restProps} />
      )}
      FooterBottom={InvestorsFooterBottom}
      {...props}
    />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  footer {
    background: ${DARK_GREEN};
  }
`
