import dynamic from 'next/dynamic';
import { Fragment } from 'react';

const defaultModules = {
  pageRelatedPages: dynamic(() =>
    import(
      /* webpackChunkName: 'RelatedPages' */
      '@bscom/content-blocks/RelatedPages'
    )
  ),
  pageIframe: dynamic(() =>
    import(
      /* webpackChunkName: 'Iframe' */
      '@bscom/content-blocks/Iframe'
    )
  ),
  pageVideoBlock: dynamic(() =>
    import(
      /* webpackChunkName: 'VideoBlock' */
      '@bscom/content-blocks/VideoBlock'
    )
  ),
  pageNewBrandTable: dynamic(() =>
    import(
      /* webpackChunkName: 'NewBrandTable' */
      '@bscom/content-blocks/NewBrandTable'
    )
  ),
  pageImageBlock: dynamic(() =>
    import(
      /* webpackChunkName: 'ImageBlock' */
      '@bscom/content-blocks/Image'
    )
  ),
  jumbotron: dynamic(() =>
    import(
      /* webpackChunkName: 'Jumbotron' */
      '@bscom/content-blocks/Jumbotron'
    )
  ),
  capaJumbotron: dynamic(() =>
    import(
      /* webpackChunkName: 'CapaJumbotron' */
      'components/content-blocks/new/Jumbotron'
    )
  ),
  capaStatements: dynamic(() =>
    import(
      /* webpackChunkName: 'CapaJumbotron' */
      'components/content-blocks/new/Statements'
    )
  ),
  leftRightRichText: dynamic(() =>
    import(
      /* webpackChunkName: 'LeftRightRichText' */
      '@bscom/content-blocks/LeftRightRichText'
    )
  ),
  pageHeadline: dynamic(() =>
    import(
      /* webpackChunkName: 'Headline' */
      '@bscom/content-blocks/Headline'
    )
  ),
  pageStatements: dynamic(() =>
    import(
      /* webpackChunkName: 'Statements' */
      '@bscom/content-blocks/Statements'
    )
  ),
  framedContentGrid: dynamic(() =>
    import(
      /* webpackChunkName: 'FramedContentGrid' */
      'components/framed-content-grid/grid'
    )
  ),
  table: dynamic(() =>
    import(
      /* webpackChunkName: 'Table' */
      '@bscom/content-blocks/Table'
    )
  ),
  twoGrid: dynamic(() =>
    import(
      /* webpackChunkName: 'TwoGrid' */
      'components/content-blocks/TwoGrid'
    )
  ),
  leftTextRightButton: dynamic(() =>
    import(
      /* webpackChunkName: 'LeftTextRightButton' */
      '@bscom/content-blocks/LeftTextRightButton'
    )
  ),
  pageContactUsForm: dynamic(() =>
    import(
      /* webpackChunkName: 'PageContactUsForm' */
      'components/content-blocks/ContactUsForm'
    )
  ),
  responsiveImage: dynamic(() =>
    import(
      /* webpackChunkName: 'ResponsiveImage' */
      '@bscom/components/Image'
    ).then((mod) => mod.ResponsiveImage)
  ),
};

export const renderContentBlock =
  (modules = defaultModules) =>
  (contentBlock, auxProps = {}) => {
    try {
      const DynamicComponent = modules[contentBlock.sys.contentType.sys.id];

      if (!DynamicComponent) {
        return (
          <div
            style={{ textAlign: 'center', margin: '40px auto' }}
          >{`${contentBlock.sys.contentType.sys.id} content block missing, get in touch with a business site developer if you see this message`}</div>
        );
      }
      DynamicComponent.displayName = contentBlock.sys.contentType.sys.id;

      return (
        DynamicComponent && (
          <Fragment key={contentBlock.sys.id}>
            <DynamicComponent
              {...(contentBlock.fields ? contentBlock.fields : contentBlock)}
              {...auxProps}
            />
          </Fragment>
        )
      );
    } catch (e) {
      if (isProduction) {
        throw e;
      } else {
        console.error(e);
        return (
          <span>
            There is no component registered for{' '}
            {contentBlock.sys.contentType.sys.id}, please add an entry in
            src/lib/renderers.js
          </span>
        );
      }
    }
  };
