import React from 'react';
import { useTranslations } from 'ilenia';
import styled from 'styled-components';

import {
  SubLink,
  SubLinkList,
  LinkWrapper,
  SubLinkSection,
  HeaderSubLinksInner,
  SubLinkSectionTitle,
  HeaderLinkWithSubLinks,
} from '@bscom/components/Navigation/NavigationLinks';
import NavigationBasic from '@bscom/components/Navigation/NavigationBasic';
import { DARK_GREEN } from 'lib/constants';

const PreWrapListItem = styled.li`
  white-space: pre-wrap;
`;

const StyledNavBasic = styled(NavigationBasic)`
  background: ${DARK_GREEN};
   div[class*="NavigationBasic__HeaderLinks"] {
    background: ${DARK_GREEN};
  }
`

const NavigationInvestor = () => {
  const [translations] = useTranslations();

  return (
    <StyledNavBasic>
      <HeaderLinkWithSubLinks label={translations['header/investors']}>
        <HeaderSubLinksInner>
          <SubLinkSection>
            <SubLinkSectionTitle>
              {translations['header/investors/shareholderservices']}
            </SubLinkSectionTitle>
            <SubLinkList>
              <li>
                <SubLink
                  data-action="shareholder-meetings"
                  data-category="shareholder-services"
                  href="/investor/shareholder-services/shareholder-meetings"
                >
                  {
                    translations[
                      'header/investors/shareholderservicesshareholdermeetings'
                    ]
                  }
                </SubLink>
              </li>
              <li>
                <SubLink
                  data-action="share-evolution-chart"
                  data-category="shareholder-services"
                  href="/investor/shareholder-services/share-evolution-chart"
                >
                  {
                    translations[
                      'header/investors/shareholderservicesshareevolutionchart'
                    ]
                  }
                </SubLink>
              </li>
              <li>
                <SubLink
                  data-action="analysts"
                  data-category="shareholder-services"
                  href="/investor/shareholder-services/analysts"
                >
                  {translations['header/investors/shareholderservicesanalysts']}
                </SubLink>
              </li>
              <li>
                <SubLink
                  data-action="investor-downloads"
                  data-category="shareholder-services"
                  href="/investor/shareholder-services/investor-downloads"
                >
                  {
                    translations[
                      'header/investors/shareholderservicesinvestordownloads'
                    ]
                  }
                </SubLink>
              </li>
            </SubLinkList>
          </SubLinkSection>
          <SubLinkSection>
            <SubLinkSectionTitle>
              {translations['header/investors/information']}
            </SubLinkSectionTitle>
            <SubLinkList>
              <li>
                <SubLink
                  linkIcon={true}
                  data-action="annual-report"
                  data-category="investor-information"
                  href="https://assets.ctfassets.net/dbztug920vik/7d7Wu45Korljg0eSo067KB/2c8436e587789e220b50cb77faeb0b47/Trustpilot_Annual_Report_2023.pdf"
                >
                  {translations['header/investors/information/annualreport']}
                </SubLink>
              </li>
              <li>
                <SubLink
                  data-action="investment-case"
                  data-category="investor-information"
                  href="/investor/investor-information/investment-case"
                >
                  {translations['header/investors/information/investmentcase']}
                </SubLink>
              </li>
              <li>
                <SubLink
                  data-action="financial-calendar"
                  data-category="investor-information"
                  href="/investor/investor-information/financial-calendar"
                >
                  {
                    translations[
                      'header/investors/information/financialcalendar'
                    ]
                  }
                </SubLink>
              </li>
              <li>
                <SubLink
                  linkIcon={true}
                  data-action="ipo-site"
                  data-category="investor-information"
                  href="https://ipo.trustpilot.com"
                >
                  {translations['header/investors/information/iposite']}
                </SubLink>
              </li>
              <li>
                <SubLink
                  linkIcon={true}
                  data-action="press-site"
                  data-category="investor-information"
                  href="https://press.trustpilot.com"
                >
                  {translations['header/investors/information/presssite']}
                </SubLink>
              </li>
              <li>
                <SubLink
                  linkIcon={true}
                  data-action="analyst-research"
                  data-category="investor-information"
                  href="https://assets.ctfassets.net/dbztug920vik/5EY1wSJCrQkPJZrV4xbQVo/d233557808af34275473a18f0550f718/Trustpilot_Initiation_Note_-_Sep_2022_-_Capital_Access.pdf"
                >
                  {translations['header/investors/information/analystresearch']}
                </SubLink>
              </li>
            </SubLinkList>
          </SubLinkSection>
        </HeaderSubLinksInner>
      </HeaderLinkWithSubLinks>

      <LinkWrapper
        translations={translations['header/investors/resultscentre']}
        identifier="resultsCentre"
        href="/results-centre"
      />
      <LinkWrapper
        translations={translations['header/investors/regulatorynews']}
        identifier="regulatoryNews"
        href="/regulatory-news"
      />
      <HeaderLinkWithSubLinks
        label={translations['header/investors/corporategovernance']}
        rightAlign
      >
        <HeaderSubLinksInner>
          <SubLinkSection>
            <SubLinkSectionTitle>
              {translations['header/investors/corporategovernance']}
            </SubLinkSectionTitle>
            <SubLinkList>
              <li>
                <SubLink
                  data-action="executive-team"
                  data-category="corporate-governance"
                  href="/corporate-governance/executive-team"
                >
                  {
                    translations[
                      'header/investors/corporategovernance/executiveteam'
                    ]
                  }
                </SubLink>
              </li>
              <li>
                <SubLink
                  linkIcon
                  data-action="anti-bribery-policy"
                  data-category="corporate-governance"
                  href="https://legal.trustpilot.com/for-everyone/anti-bribery-policy"
                >
                  {
                    translations[
                      'header/investors/corporategovernance/antibriberypolicy'
                    ]
                  }
                </SubLink>
              </li>
              <li>
                <SubLink
                  linkIcon
                  data-action="modern-slavery-policy"
                  data-category="corporate-governance"
                  href="https://legal.trustpilot.com/for-everyone/modern-slavery-code-of-conduct"
                >
                  {
                    translations[
                      'header/investors/corporategovernance/modernslaverypolicy'
                    ]
                  }
                </SubLink>
              </li>
              <li>
                <SubLink
                  linkIcon
                  data-action="code-of-ethics"
                  data-category="corporate-governance"
                  href="https://legal.trustpilot.com/for-everyone/code-of-ethics"
                >
                  {
                    translations[
                      'header/investors/corporategovernance/codeofethics'
                    ]
                  }
                </SubLink>
              </li>
              <li>
                <SubLink
                  linkIcon
                  data-action="sustainability-report"
                  data-category="corporate-governance"
                  href="https://downloads.ctfassets.net/dbztug920vik/1sja6MOHhth1x8ySALxgi9/7799ab3ebeed5d83d63f81d8dca8f6f5/Trustpilot_SR_2022.pdf"
                >
                  {
                    translations[
                      'header/investors/corporategovernance/sustainabilityreport'
                    ]
                  }
                </SubLink>
              </li>
            </SubLinkList>
          </SubLinkSection>
          <SubLinkSection>
            <SubLinkSectionTitle empty>&nbsp;</SubLinkSectionTitle>
            <SubLinkList>
              <li>
                <SubLink
                  data-action="executive-team"
                  data-category="corporate-governance"
                  href="/corporate-governance/articles-of-association"
                >
                  {
                    translations[
                      'header/investors/corporategovernance/articlesofassociation'
                    ]
                  }
                </SubLink>
              </li>
              <li>
                <SubLink
                  data-action="committee-terms-of-reference"
                  data-category="corporate-governance"
                  href="/corporate-governance/committee-terms-of-reference"
                >
                  {
                    translations[
                      'header/investors/corporategovernance/committeetermsofreference'
                    ]
                  }
                </SubLink>
              </li>
              <PreWrapListItem>
                <SubLink
                  linkIcon
                  target="_blank"
                  data-action="schedule-of-matters-reserved-for-the-board"
                  data-category="corporate-governance"
                  href="https://assets.ctfassets.net/dbztug920vik/nYzIOQDzC1z6cyXDVT5jn/022ffa475b2eedbd2eb8de0619e930da/Schedule_of_matters_reserved_to_the_Board_final_June_2022.pdf"
                >
                  {
                    translations[
                      'header/investors/corporategovernance/scheduleofmattersreservedfortheboard'
                    ]
                  }
                </SubLink>
              </PreWrapListItem>
            </SubLinkList>
          </SubLinkSection>
        </HeaderSubLinksInner>
      </HeaderLinkWithSubLinks>
    </StyledNavBasic>
  );
};

export default NavigationInvestor;
