import { useTranslations } from 'ilenia';
import React, { useState, useEffect } from 'react';

import { restMapper } from '@bscom/utilities/restMapper';
import { HeadMetaTags } from '@bscom/components/HeadMetaTags';
import { PillarNavigation } from '@bscom/components/PillarNavigation';
import { PromotionalBanners } from '@bscom/components/PromotionalBanner';

import { pageMeta } from 'src/metaMappers';
import { cfulClient } from 'src/cfulClient';
import { BaseLayout } from 'layouts/BaseLayout';
import { renderContentBlock } from 'src/block-renderers';

export const GenericPage = ({
  page,
  HeaderComponent,
  hideNavigation = false,
  ...props
}) => {
  const [translations] = useTranslations();
  const [showBanners, setShowBanners] = useState(false);

  useEffect(() => {
    setShowBanners(true);
  }, []);

  return (
    <>
      {page.banner && showBanners && (
        <PromotionalBanners banners={[page.banner]} />
      )}
      <BaseLayout HeaderComponent={HeaderComponent}>
        <HeadMetaTags {...pageMeta({ ...page, ...props, translations })} />
        {!hideNavigation && (
          <PillarNavigation
            title={page.title.split('-').pop()}
            innerTitle="Home"
          />
        )}
        {page?.contentCollection?.items.map(renderContentBlock())}
      </BaseLayout>
    </>
  );
};

export const fetchPageData = async ({ slug, ctx }) => {
  const result = await cfulClient.getEntries({
    content_type: 'page',
    locale: 'en-US',
    include: 10,
    'fields.slug[in]': slug,
  });
  if (!result.items[0]) {
    return { notFound: true };
  }

  const page = restMapper(result.items[0], 12);

  return {
    props: { page },
  };
};
