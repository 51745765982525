export const pageMeta = ({
  metaTitle,
  metaNoindex,
  translations,
  metaDescription,
}) => {
  return {
    metaNoindex,
    metaOgType: 'website',
    metaTitle: metaTitle || translations['meta/title'],
    metaDescription: metaDescription || translations['meta/description'],
  };
};
